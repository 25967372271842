import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer class="footer-area">
        <div class="footer-widget">
          <div class="container">
            <div class="row footer-widget-wrapper pt-100 pb-70">
              <div class="col-md-6 col-lg-3">
                <div class="footer-widget-box about-us">
                  <Link to="#" class="footer-logo">
                    <img src="assets/img/logo/logo.png" alt="" />
                  </Link>
                  <p class="mb-4">
                    We deliver agricultural and rural development services,
                    empowering farmers and communities.
                  </p>
                  <ul class="footer-contact">
                    <li>
                      <div class="footer-call">
                        <div class="footer-call-icon">
                          <i class="fal fa-headset"></i>
                        </div>
                        <div class="footer-call-info">
                          <h6>24/7 Call Service</h6>
                          <Link to="tel:+21236547898">
                            9931610419 / 8409302239
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <i
                        class="far fa-map-marker-alt"
                        style={{
                        
                          padding: "0px 15px 0px 9px",

                          fontSize: "20px",
                        }}
                      ></i>
                      Priyadarshi Nagar, D.P.S., Rupaspur Thana More, Baily
                      Road, Patna-801503(BH.)
                    </li>
                    <li>
                      <Link to="khistizagrotech@gmail.com">
                        <i class="far fa-envelopes"></i>
                        khistizagrotech@gmail.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="footer-widget-box list">
                  <h4 class="footer-widget-title">Our Company</h4>
                  <ul class="footer-list">
                    <li>
                      <Link to="/about">
                        <i class="fas fa-angle-double-right"></i> About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/ourstructure">
                        <i class="fas fa-angle-double-right"></i> Our Structure
                      </Link>
                    </li>
                    <li>
                      <Link to="/whoweare">
                        <i class="fas fa-angle-double-right"></i> Who We Are
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery">
                        <i class="fas fa-angle-double-right"></i> Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/mediaGallery">
                        <i class="fas fa-angle-double-right"></i> Media Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <i class="fas fa-angle-double-right"></i> Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="footer-widget-box list">
                  <h4 class="footer-widget-title">Our Functions</h4>
                  <ul class="footer-list">
                    <li>
                      <Link to="/functions">
                        <i class="fas fa-angle-double-right"></i> Functions
                      </Link>
                    </li>
                    <li>
                      <Link to="/ourdevelopmentfunction">
                        <i class="fas fa-angle-double-right"></i> Our
                        Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/rainbowagropark">
                        <i class="fas fa-angle-double-right"></i> Rainbow Agro
                        Park
                      </Link>
                    </li>
                    <li>
                      <Link to="/BiofertilizerManufacturing">
                        <i class="fas fa-angle-double-right"></i> Biofertilizer
                      </Link>
                    </li>
                    <li>
                      <Link to="/Vermicompost">
                        <i class="fas fa-angle-double-right"></i> Vermi Compost
                      </Link>
                    </li>
                    <li>
                      <Link to="/MushroomUnit">
                        <i class="fas fa-angle-double-right"></i> Mushroom Unit
                      </Link>
                    </li>
                    <li>
                      <Link to="/PoulitryUnit">
                        <i class="fas fa-angle-double-right"></i> Poulitry Unit
                      </Link>
                    </li>
                    <li>
                      <Link to="/BaterUnit">
                        <i class="fas fa-angle-double-right"></i> Bater Unit
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="footer-widget-box list">
                  <h4 class="footer-widget-title">Our Functions</h4>
                  <ul class="footer-list">
                    <li>
                      <Link to="/horticultureUnit">
                        <i class="fas fa-angle-double-right"></i> Horticulture
                        Unit
                      </Link>
                    </li>
                    <li>
                      <Link to="/fisherUnit">
                        <i class="fas fa-angle-double-right"></i> Fisher Unit
                      </Link>
                    </li>
                    <li>
                      <Link to="/biofloc">
                        <i class="fas fa-angle-double-right"></i> Biofloc
                      </Link>
                    </li>
                    <li>
                      <Link to="/organicFarming">
                        <i class="fas fa-angle-double-right"></i> Organic
                        Farming
                      </Link>
                    </li>
                    <li>
                      <Link to="/organicVegitable">
                        <i class="fas fa-angle-double-right"></i> Organic
                        Vegitable
                      </Link>
                    </li>
                    <li>
                      <Link to="/StraberryProduction">
                        <i class="fas fa-angle-double-right"></i> Straberry
                        Production
                      </Link>
                    </li>
                    <li>
                      <Link to="/polyhouse">
                        <i class="fas fa-angle-double-right"></i> Polyhouse
                      </Link>
                    </li>
                    <li>
                      <Link to="/azollaUnit">
                        <i class="fas fa-angle-double-right"></i> Azolla Unit
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="footer-widget-box list">
                  <h4 class="footer-widget-title">Our Functions</h4>
                  <ul class="footer-list">
                    <li>
                      <Link to="/goateryUnit">
                        <i class="fas fa-angle-double-right"></i> Goatery Unit
                      </Link>
                    </li>
                    <li>
                      <Link to="/IrrigationSystem">
                        <i class="fas fa-angle-double-right"></i> Irrigation
                        System
                      </Link>
                    </li>
                    <li>
                      <Link to="/IntegratedFarming">
                        <i class="fas fa-angle-double-right"></i> Integrated
                        Farming
                      </Link>
                    </li>
                    <li>
                      <Link to="/fposProject">
                        <i class="fas fa-angle-double-right"></i> FPOs Project
                      </Link>
                    </li>
                    <li>
                      <Link to="/tribalDevelopmentFund">
                        <i class="fas fa-angle-double-right"></i> Tribal
                        Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/offFarmDevelopment">
                        <i class="fas fa-angle-double-right"></i> OffFarm
                        Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/watershedDevelopment">
                        <i class="fas fa-angle-double-right"></i> Watershed
                        Development
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div class="col-md-6 col-lg-3">
                        <div class="footer-widget-box list">
                            <h4 class="footer-widget-title">Newsletter</h4>
                            <div class="footer-newsletter">
                                <p>Subscribe Our Newsletter To Get Latest Update And News</p>
                                <div class="subscribe-form">
                                    <form action="#">
                                        <div class="form-group">
                                            <div class="form-group-icon">
                                                <input type="email" class="form-control" placeholder="Your Email"/>
                                                <i class="far fa-envelope"></i>
                                            </div>
                                        </div>
                                        <button class="theme-btn" type="submit">
                                            Subscribe Now <i class="far fa-paper-plane"></i>
                                        </button>
                                        <p><i class="far fa-lock"></i> Your information is safe with us.</p>
                                    </form>
                                </div>
                            </div>
                            <div class="footer-payment-method">
                                <h6>We Accept:</h6>
                                <div class="payment-method-img">
                                    <img src="assets/img/payment/paypal.svg" alt=""/>
                                    <img src="assets/img/payment/mastercard.svg" alt=""/>
                                    <img src="assets/img/payment/visa.svg" alt=""/>
                                    <img src="assets/img/payment/discover.svg" alt=""/>
                                    <img src="assets/img/payment/american-express.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
          </div>
        </div>
        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-md-6 align-self-center">
                <p class="copyright-text">
                  &copy; Copyright <span id="date"></span>{" "}
                  <Link to="#">@ Khistiz Agro Tech</Link> Develop By Sanity Softwares
                </p>
              </div>
              <div class="col-md-6 align-self-center">
                <ul class="footer-social">
                  <li>
                    <Link to="#">
                      <i class="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i class="fab fa-x-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i class="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i class="fab fa-youtube"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
