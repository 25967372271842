import React, { useState } from "react";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS
import { Link } from "react-router-dom";

function MediaGallery() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
  
    // Array of image paths for the gallery
    const images = [
     
      "assets/img/gallery/37.jpg",
      "assets/img/gallery/38.jpg",
      "assets/img/gallery/39.jpg",
      "assets/img/gallery/40.jpg",
      "assets/img/gallery/41.jpg",
      "assets/img/gallery/42.jpg",
      "assets/img/gallery/43.jpg",
      "assets/img/gallery/44.jpg",
      "assets/img/gallery/45.jpg",
      "assets/img/gallery/46.jpg",
      "assets/img/gallery/47.jpg",
      "assets/img/gallery/48.jpg",
      "assets/img/gallery/49.jpg",
      "assets/img/gallery/50.jpg",
      "assets/img/gallery/51.jpg",
      "assets/img/gallery/52.jpg",
      "assets/img/gallery/53.jpg",
      "assets/img/gallery/54.jpg",
      "assets/img/gallery/55.jpg",
      "assets/img/gallery/56.jpg",
      "assets/img/gallery/57.jpg",
      "assets/img/gallery/58.jpg",
     
      
    
    
    ];
  return (
    <main className="main">
      {/* breadcrumb */}
      <div
        className="site-breadcrumb"
        style={{
          background: "url('assets/img/breadcrumb/02.jpg')",
        }}
      >
        <div className="container">
          <h2 className="breadcrumb-title"> Media Gallery</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">Media Gallery</li>
          </ul>
        </div>
      </div>

      {/* gallery-area */}
      <div className="gallery-area py-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay=".25s"
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Media Gallery</span>
                <h2 className="site-title">Khistiz Agro Media Gallery</h2>
              </div>
            </div>
          </div>
          <div className="row popup-gallery">
            {images.map((image, index) => (
              <div className="col-md-4" key={index}>
                <div className="gallery-item">
                  <div className="gallery-img">
                    <img
                      src={image}
                      alt={`Gallery ${index + 1}`}
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "100%", // Makes image stretch to the container's width
                        height: "250px", // Fixed height
                        objectFit: "cover", // Ensures the image covers the space properly
                      }}
                    />
                  </div>
                  <div className="gallery-content">
                    <Link
                      className="popup-img gallery-link"
                      to={image}
                      onClick={(e) => {
                        e.preventDefault();
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    >
                      <i className="far fa-plus"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* gallery-area end */}

      {/* Lightbox for Viewing Images */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]} // Set the main image
          nextSrc={images[(photoIndex + 1) % images.length]} // Next image
          prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
          onCloseRequest={() => setIsOpen(false)} // Close the lightbox
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </main>
  )
}

export default MediaGallery