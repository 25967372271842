import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function FposProject() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    
    "assets/img/fpos/02.jpg",
    "assets/img/fpos/03.jpg",
    "assets/img/fpos/04.jpg",
   
  ];

  
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">FPOs Projects (SAMRIDHI)</h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active">FPOs Projects (SAMRIDHI)</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
            
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images">
                          <img
                            src={"assets/img/fpos/01.jpg"}
                            alt={`Room`}
                            class="carousel-image"
                          />
                        </div>
                        
                      </div>
                    </div>

                  
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    {/* <h4 class="booking-title">FPOs Projects (SAMRIDHI)</h4> */}
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Our passion is to bring
                        about solutions at a scale that eliminate inequities and
                        marginalisation and create wealth for the marginalised.
                        We strongly believe that dynamic, strong and responsible
                        private sector is a critical ingredient to achieve this,
                        combined with synergetic relationships between people,
                        civil society and public bodies to ensure equity. KAT
                        has been working on developing Farmer Producer
                        Organizations (FPOs) from the last year. As Producer
                        Organization Development and Upliftment Corpus (PRODUCE)
                        Fund was created in NABARD by GOI during 2014-15 and
                        initial contribution for the promotion of Farmer
                        Producer Organizations (FPOs). 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
              <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Under this Fund, KAT
                        works with over 14 FPOs of which are under direct
                        implementation and are being developed in partnership
                        with NABARD. KAT is working with 4380 farmers and
                        promoting 14 FPOs, supported by National Bank for Rural
                        and Development (NABARD). KAT has also giving the
                        Technical Assistance of FPOs / FPCs from Bihar and
                        Jharkhand which are either associated with NABARD or
                        State Agriculture Department. 
                      </li>
                    </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-4" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
        <div style={{ textAlign: "center", marginTop: "-50px" }}>
  <img 
    src="assets/img/fpos/05.jpg" 
    alt="FPO Image" 
    style={{ width: "90%", height: "auto" }} 
  />
</div>
      </main>
    </>
  );
}

export default FposProject;
