import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function PolyhouseUnit() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/polyhouse/02.jpg",
    "assets/img/polyhouse/03.jpg",
    "assets/img/polyhouse/04.jpg",
    
  ];

 
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">POLYHOUSE (Greenhouse) Unit</h2>
            <ul class="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li class="active">POLYHOUSE (Greenhouse) Unit</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "510px" }}>
                          <img
                            src={"assets/img/polyhouse/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                        
                      </div>
                    </div>

                    
                  </div>
                </div>
                
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">POLYHOUSE (Greenhouse) Unit</h4>
                    <ul class="listing-side-list">
                      <li  style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>KAPL has also trained to
                        Farmers in Greenhouse Technology and develops a new and
                        modern era of Farming model in Agriculture system for
                        the Farmers of India.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer the Training to
                        Farmers and Rural Youth in Greenhouse Management with
                        proper management for their Doubling Income, it can be
                        made our Farmers Self-Sufficient on the way of
                        Atmanirbhar Bihar.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The main focus of the KAPL
                        is on creating a demonstration effect through individual
                        where success of one farmer could motivate others to
                        take up Polyhouse Technology.
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
              <ul class="listing-side-list">
                      <li  style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Khistiz Agro Tech (KAPL) has also taken initiatives to
                        Promote POLYHOUSE (Greenhouse) Technology on
                        demonstration purpose.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>POLYHOUSE is a type of GREENHOUSE where specialized
                        polythene sheet is used as a covering material under
                        which the Crops can be grown in partially or fully
                        controlled Climatic conditions. Traditionally, the
                        Greenhouses were constructed on Wooden frames where the
                        glass was used as a cladding material. But now with the
                        advancement of Plastic Technology, it became possible to
                        replace the glass with plastic material. Polyhouses are
                        more suitable for Tropical and Subtropical regions like
                        India.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Modern-day Polyhouses are built on G.I. steel frame and
                        are covered with Plastic, which is fixed on the frame
                        with Aluminium Grippers. The White Plastic film used for
                        covering is od high Quality, 200 to 300 microns
                        thickness for degradation due to UV and weather. Mostly
                        Drip Irrigation System is Installed inside a polyhouse
                        for Watering Purpose.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>consultation from Crop selection, Selection of Organic
                        Fertilizers, to complete Modern Technology and
                        Management of POLYHOUSE.
                      </li>
                      
                    </ul>
                     
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-4" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default PolyhouseUnit;
