import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function RainbowAgroPark() {
  const images = [
    "assets/img/rainbow/02.jpg",
    "assets/img/rainbow/03.jpg",
    "assets/img/rainbow/04.jpg",
    "assets/img/rainbow/05.jpg",
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">Rainbow Agro Park</h2>
            <ul class="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li class="active">Rainbow Argo Park</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images">
                          <img
                            src={"assets/img/rainbow/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                        
                      </div>
                    </div>

                    <div class="listing-item">
                      <h4 class="mb-3"  style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        Rainbow Agro Park (RAP)-An initiative of KAT based on
                        integrated farming system
                      </h4>
                      <p  style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        A judicious mix of agricultural enterprises like Dairy,
                        poultry, fish farming, Duck farming, Apiculture with
                        production of agricultural crops including vegetable
                        farming based on organic farming and ploy house
                        technology has been promoted at rainbow Agro park which
                        is located at Village- Nehusa, Block-Harnaut, District-
                        Nalanda (Bihar). Officials of various department and
                        farmers frequently visited our farm house.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                 
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">SERVICES</h4>
                    <h5>KAT offers services in following areas:-</h5>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: "justify", lineHeight: "1.6" }}>
                        <i class="fas fa-check"></i> Supply of organic inputs,
                        particularly vermi compost, as we are the leading
                        producer of vermin compost in Bihar.
                      </li>
                      <li>
                        <i class="fas fa-check"></i> Consultancy in organic
                        farming.
                      </li>
                      <li>
                        <i class="fas fa-check"></i> Pest management through
                        organic methods.
                      </li>
                      <li style={{ textAlign: "justify", lineHeight: "1.6" }}>
                        <i class="fas fa-check"></i> Greenhouse technology with
                        fabrication and cultivation.
                      </li>
                      <li style={{ textAlign: "justify", lineHeight: "1.6" }}>
                        <i class="fas fa-check"></i> Plant tissue culture
                        technology.
                      </li>
                      <li style={{ textAlign: "justify", lineHeight: "1.6" }}>
                        <i class="fas fa-check"></i> Integrated farming
                        technology.
                      </li>
                      <li style={{ textAlign: "justify", lineHeight: "1.6" }}>
                        <i class="fas fa-check"></i> Biofertilizer manufacturing
                        & marketing.
                      </li>
                      <li style={{ textAlign: "justify", lineHeight: "1.6" }}>
                        <i class="fas fa-check"></i> Design and layout of Drip
                        and sprinkle irrigation.
                      </li>
                      <li style={{ textAlign: "justify", lineHeight: "1.6" }}>
                        <i class="fas fa-check"></i> SRI method of cultivation.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
        
      </main>
    </>
  );
}

export default RainbowAgroPark;
