import React from "react";

import { Link } from "react-router-dom";
function About() {
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/02.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">About Us</h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="index.html">Home</Link>
              </li>
              <li class="active">About Us</li>
            </ul>
          </div>
        </div>

        {/* <!-- about area --> */}
        <div class="about-area py-120 mb-30">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div
                  class="about-left wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay=".25s"
                >
                  <div class="about-img">
                    <img src="assets/img/about/02.jpg" alt="" />
                  </div>
                  <div class="about-experience">
                    <h1
                      style={{
                        fontSize: "26px",
                      }}
                    >
                      Mr. Sunil Kumar
                    </h1>
                    <span class="about-experience-text" style={{
                        fontSize: "20px", lineHeight: "1.2",
                      }}>
                      Chairman cum Managing Director,
                      <br />
                      Khistiz Agro Tech
                      <br />
                      06th October 2020
                    </span>
                  </div>
                  {/* <div className="about-experience">
      <h1 className="experience-title">
        Mr. Sunil Kumar
      </h1>
      <span className="experience-text">
        Chairman cum Managing Director,  
        <br />
        Khistiz Agro Tech  
        <br />
        06th October 2020
      </span>
      </div> */}
                  <div class="about-shape">
                    <img src="assets/img/shape/01.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="about-right wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".25s"
                >
                  <div class="site-heading mb-3">
                    <span class="site-title-tagline">About Us</span>
                    <h2 class="site-title">
                      Celebrating 12 Years of Empowering Rural India with
                      Khistiz Agro Tech
                    </h2>
                  </div>
                  <p
                    class="about-text"
                    style={{ textAlign: "justify", lineHeight: "1.6" }}
                  >
                    “It gives me immense pleasure to present a brief outline of
                    Khistiz Agro Tech (KAPL). We have completed 12 years of
                    glorious service for the Agriculture & Allied Sector on 06th
                    October 2020. The objective of this brochure is to share how
                    we are making a difference to the lives of many across the
                    vast stretches of rural India, in our own organic way; a
                    difference whose impact cannot be always understood through
                    the prism of numbers alone and hence the title ‘Dare to be
                    Different with our Farmers and Rural Women’.”
                  </p>
                  <div class="about-list-wrapper">
                    <ul class="about-list list-unstyled">
                      <li>
                        <div class="about-icon">
                          <span class="fas fa-check-circle"></span>
                        </div>
                        <div class="about-list-text">
                          <p>
                            12 years of impactful service in the Agriculture &
                            Allied Sector
                          </p>
                        </div>
                      </li>
                      <li>
                        <div class="about-icon">
                          <span class="fas fa-check-circle"></span>
                        </div>
                        <div class="about-list-text">
                          <p>
                            Empowering farmers and rural women across rural
                            India
                          </p>
                        </div>
                      </li>
                      <li>
                        <div class="about-icon">
                          <span class="fas fa-check-circle"></span>
                        </div>
                        <div class="about-list-text">
                          <p>
                            Driving organic growth with a focus on sustainable
                            development
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="about-bottom">
                    <div class="about-call">
                      <div class="about-call-icon">
                        <i class="fal fa-user-headset"></i>
                      </div>
                      <div class="about-call-content">
                        <h5 class="about-call-number">12 +
                        Years Of Experience</h5>
                        {/* <h5 class="about-call-number">
                          Chairman cum Managing Director,
                        </h5>
                        <h5 class="about-call-number">Khistiz Agro Tech</h5>
                        <h5>06th October 2020</h5> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- about area end --> */}

        {/* <!-- counter area --> */}
        {/* <div class="counter-area pt-80 pb-80">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <i class="fal fa-check-circle"></i>
                    </div>
                    <div class="counter-content">
                        <div class="counter-number">
                            <span class="counter" data-count="+" data-to="120" data-speed="3000">120</span>
                            <span class="counter-sign">k</span>
                        </div>
                        <h6 class="title">Booking Done</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <i class="fal fa-earth-americas"></i>
                    </div>
                    <div class="counter-content">
                        <div class="counter-number">
                            <span class="counter" data-count="+" data-to="200" data-speed="3000">200</span>
                            <span class="counter-sign">+</span>
                        </div>
                        <h6 class="title">Our Destination</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <i class="fal fa-smile"></i>
                    </div>
                    <div class="counter-content">
                        <div class="counter-number">
                            <span class="counter" data-count="+" data-to="40" data-speed="3000">40</span>
                            <span class="counter-sign">k</span>
                        </div>
                        <h6 class="title">Happy Clients</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <i class="fal fa-users"></i>
                    </div>
                    <div class="counter-content">
                        <div class="counter-number">
                            <span class="counter" data-count="+" data-to="180" data-speed="3000">180</span>
                            <span class="counter-sign">+</span>
                        </div>
                        <h6 class="title">Our Partners</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}
        {/* <!-- counter area end --> */}

        {/* <!-- team-area --> */}

        {/* <!-- team-area end --> */}

        {/* <!-- testimonial area --> */}

        {/* <!-- testimonial area end --> */}

        {/* <!-- feature area --> */}

        {/* <!-- feature area end --> */}

        {/* <!-- cta-area --> */}

        {/* <!-- cta-area end --> */}

        {/* <!-- partner area --> */}

        {/* <!-- partner area end --> */}
      </main>
    </>
  );
}

export default About;
