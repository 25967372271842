import React from 'react';
import { Link,useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';

function Navbar() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <header className="header">

        {/* <!-- header-top --> */}
        
        {/* <div className="header-top" >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="header-top-left">
                  <div className="top-social">
                    <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                    <Link to="#"><i className="fab fa-x-twitter"></i></Link>
                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                    <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                  </div>
                  <div className="top-contact-info">
                    <ul>
                      <li>
                        <Link to="tel:+21234567897">
                          <i className="far fa-phone-arrow-down-left"></i>+09931610419 / 8409302239
                        </Link>
                      </li>
                      <li>
                        <Link to="mailto:khistizagrotech@gmail.com">
                          <i className="far fa-envelopes"></i>khistizagrotech@gmail.com
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        
                      {/* connect2khistizagro@gmail.com */}

        {/* <!-- Main Navigation --> */}
        <div className="main-navigation" >
          <nav className="navbar navbar-expand-lg" style={{ backgroundColor: 'white' }}>
            <div className="container" >
            <nav
      
    >
      <Link to="/" style={{ textDecoration: "none" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="assets/img/logo/favicon.png"
            alt="logo"
            style={{
              height: scrolled ? "40px" : "50px",
              width: "auto",
              transition: "height 0.3s ease",
              marginRight: "10px",
            }}
            
           className="nav-logo"
          />
          <h3
            style={{
              margin: 0,
              fontSize: scrolled ? "1.6rem" : "1.8rem",
              transition: "color 0.3s ease, font-size 0.3s ease",
            }}
          >
            Khistiz Agro Tech
          </h3>
        </div>
      </Link>
    </nav>


              <div className="mobile-menu-right">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-btn-icon"><i className="far fa-bars"></i></span>
                </button>
              </div>
              <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                  {/* Home */}
                  <li className="nav-item">
                    <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}style={{fontSize:"20px"}} to="/">Home</Link>
                  </li>
                 {/* About */}
                 <li className="nav-item dropdown">
                    <Link className={`nav-link dropdown-toggle ${location.pathname === '/about' || location.pathname === '/ourstructure' || location.pathname === '/whoweare' ? 'active' : ''}`}style={{fontSize:"20px"}} to="#" data-bs-toggle="dropdown">About</Link>
                    <ul className="dropdown-menu fade-down">
                      <li><Link className="dropdown-item" to="/about">About Us</Link></li>
                      <li><Link className="dropdown-item" to="/ourstructure">Our Structure</Link></li>
                      <li><Link className="dropdown-item" to="/whoweare">Who We Are</Link></li>
                    </ul>
                  </li>

                  {/* Functions */}
                  <li className="nav-item dropdown">
                    <Link  style={{fontSize:"20px"}}   className={`nav-link dropdown-toggle ${['/functions', '/ourdevelopmentfunction', '/rainbowagropark', '/BiofertilizerManufacturing', '/Vermicompost', '/MushroomUnit', '/PoulitryUnit', '/BaterUnit', '/horticultureUnit', '/fisherUnit', '/biofloc', '/organicFarming', '/organicVegitable', '/StraberryProduction', '/polyhouse', '/azollaUnit', '/goateryUnit', '/IrrigationSystem', '/IntegratedFarming', '/fposProject', '/tribalDevelopmentFund', '/watershedDevelopment', '/offFarmDevelopment'].includes(location.pathname) ? 'active' : ''}`} to="#" data-bs-toggle="dropdown">Function</Link>
                    <div className="dropdown-menu mega-menu fade-down" style={{ width: "700px" }}>
                      <div className="row" >
                        <div className="col-lg-4">
                          <ul >
                            <li><Link className="dropdown-item" to="/functions">Functions</Link></li>
                            <li><Link className="dropdown-item" to="/ourdevelopmentfunction">Our Development </Link></li>
                            <li><Link className="dropdown-item" to="/rainbowagropark">Rainbow Agropark</Link></li>
                            <li><Link className="dropdown-item" to="/BiofertilizerManufacturing">Biofertilizer</Link></li>
                            <li><Link className="dropdown-item" to="/Vermicompost">Vermicompost</Link></li>
                            <li><Link className="dropdown-item" to="/MushroomUnit">Mushroom Unit</Link></li>
                            <li><Link className="dropdown-item" to="/PoulitryUnit">Poultry Unit</Link></li>
                            <li><Link className="dropdown-item" to="/BaterUnit">Bater Unit</Link></li>
                            
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <ul>
                            <li><Link className="dropdown-item" to="/horticultureUnit">Horticulture Unit</Link></li>
                            <li><Link className="dropdown-item" to="/fisherUnit">Fisher Unit</Link></li>
                            <li><Link className="dropdown-item" to="/biofloc">Biofloc</Link></li>
                            <li><Link className="dropdown-item" to="/organicFarming">Organic Farming</Link></li>
                            <li><Link className="dropdown-item" to="/organicVegitable">Organic Vegetable</Link></li>
                            <li><Link className="dropdown-item" to="/StraberryProduction">Strawberry Production</Link></li>
                            <li><Link className="dropdown-item" to="/polyhouse">Polyhouse</Link></li>
                            <li><Link className="dropdown-item" to="/azollaUnit">Azolla Unit</Link></li>
                            
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <ul>
                            <li><Link className="dropdown-item" to="/goateryUnit">Goatery Unit</Link></li>
                            <li><Link className="dropdown-item" to="/IrrigationSystem">Irrigation System</Link></li>
                            <li><Link className="dropdown-item" to="/IntegratedFarming">Integrated Farming</Link></li>
                            <li><Link className="dropdown-item" to="/fposProject">FPOs Project</Link></li>
                            <li><Link className="dropdown-item" to="/tribalDevelopmentFund">Tribal Development </Link></li>
                            <li><Link className="dropdown-item" to="/watershedDevelopment">Watershed Development</Link></li>
                            <li><Link className="dropdown-item" to="/offFarmDevelopment">Off-Farm Development</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  
                  <li className="nav-item">
                    <Link style={{fontSize:"20px"}}className={`nav-link ${location.pathname === '/products' ? 'active' : ''}`} to="/products">Product Basket</Link>
                  </li>
                  {/* Gallery */}
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/gallery">Gallery</Link>
                  </li> */}
                   <li className="nav-item dropdown">
                    <Link style={{fontSize:"20px"}}  className={`nav-link dropdown-toggle ${location.pathname === '/gallery' || location.pathname === '/mediaGallery' || location.pathname === '/video' ? 'active' : ''}`} to="#" data-bs-toggle="dropdown">Galleries</Link>
                    <ul className="dropdown-menu fade-down">
                      <li><Link className="dropdown-item" to="/gallery">Gallery</Link></li>
                      <li><Link className="dropdown-item" to="/mediaGallery">Media Gallery</Link></li>
                      <li><Link className="dropdown-item" to="/video">Video</Link></li>
                    </ul>
                  </li>

                  {/* Contact */}
                  <li className="nav-item">
                    <Link style={{fontSize:"20px"}} className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}to="/contact">Contact</Link>
                  </li>

                  
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;
