import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function BiofertilizerManufacturing() {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/biofertilizer/02.jpg",
    "assets/img/biofertilizer/03.jpg",
    "assets/img/biofertilizer/04.jpg",
    "assets/img/biofertilizer/05.jpg",
    "assets/img/biofertilizer/06.jpg",
    
  ];

 

  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">
              Biofertilizer Manufacturing & marketing{" "}
            </h2>
            <ul class="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li class="active">Biofertilizer Manufacturing & marketing </li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "520px" }}>
                          <img
                            src={"assets/img/biofertilizer/01.jpg"}
                            alt={`Room`}
                            class="carousel-image"
                          />
                        </div>
                      </div>
                    </div>

                   
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">Biofertilizer Manufacturing & marketing</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Organisation has made
                        available many beneficial organic products, including
                        efficient vermicompost famous as DHARTI AMRIT which is a
                        special technology product. KAT is manufacturing Bio
                        organic products (Bio fertilizer) like in Liquid form
                        like; - FALGU Azospi (Azospirillum), KOSHI Azoto
                        (Azotobactor), GANGA Phospho (PSB) and SONE Rhizo
                        (Rhizobium)
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>In Powder form:- FALGU
                        (Azospirillum), KOSHI (Azotobactor), GANGA (PSB) and
                        SONE (Rhizobium) a series of products which are Growth
                        Enhancers & Biofertilizers to improve the plant
                        performance.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                      {/* <h4 class="mb-3">
                        {" "}
                      </h4> */}
                      <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>KAT is Agro Input provider Organisation. KAT is pioneer
                        in manufacture, Marketing & supply of series of many
                        beneficial products for organic farming. Organisation’s
                        motto of “Progressive Diversion from Chemical Farming to
                        organic Farming” has been successfully supported by
                        providing many impressive options. Our policy is to make
                        available high-quality products, useful & profitable to
                        the end user.
                      </li>
                     
                    </ul>
                    
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        
 <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default BiofertilizerManufacturing;
