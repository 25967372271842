import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function OrganicVegetableFarming() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/organicveg/02.jpg",
    "assets/img/organicveg/03.jpg",
    "assets/img/organicveg/04.jpg",
  ];

  
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">Organic Vegetable Farming System</h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active">Organic Vegetable Farming System</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "540px" }}>
                          <img
                            src={"assets/img/organicveg/01.jpg"}
                            alt={`Room`}
                            class="carousel-image"
                          />
                        </div>
                      </div>
                    </div>

                   
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content ">
                    <h4 class="booking-title">Organic Vegetable Farming System</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>KAPL has also trained
                        to Farmers in ORGANIC FARMING Technology and develops a
                        new and modern technology of Organic Farming model in
                        Agriculture system for the Farmers.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The main focus of the KAPL
                        is on creating a demonstration effect through individual
                        where success of one Organic Vegetable farmer could
                        motivate others to take up Vegetable through Organic
                        farming.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer Complete
                        consultation from Crop selection, Selection of Organic
                        Fertilizers, to complete Modern Technology and
                        Management of Organic Farming.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                      
                      <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Khistiz Agro Tech (KAPL) has taken initiatives to
                        Promote Organic Vegetable Farming on large scale.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Vegetables in addition to being a source of highly
                        digestible carbohydrate and nutritionally complete
                        protein, are also an excellent source of essential
                        nutrients, glucosinolates, antioxidants, fibre and
                        vitamins, particularly niacin, riboflavin, Thiamin and
                        vitamins A and C, etc. Green leafy Vegetables such as
                        amaranth, bathua and spinach etc., are cheaper source of
                        folic acid. Vegetables are the best resource for
                        overcoming micronutrient deficiencies. Judicious mix of
                        vegetables can provide the recommended daily allowance
                        for vitamin, folate as well as significant amounts of
                        other essential mineral nutrients required for human
                        health. Moreover, most of the vegetables, being short
                        duration crops, fit very well in the different multiple
                        and inter-cropping system and are capable of giving very
                        high yields and very high economic returns to the
                        growers in a short period of time, besides generating on
                        farm and off farm employment as a result, in recent
                        years major emphasis is given for commercial
                        exploitation of vegetable crops. Organics added in its
                        cultivation will lead to more value addition providing
                        better income and a sustainable production.
                      </li>
                     
                    </ul>
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        
 <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-4" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default OrganicVegetableFarming;
