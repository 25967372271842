import React, { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function IrrigationSystem() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    // "assets/img/irrigation/02.jpg",
    "assets/img/irrigation/03.jpg",
    "assets/img/irrigation/04.jpg",
    "assets/img/irrigation/05.jpg",
   
  ];

 
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">
              IRRIGATION SYSTEM (Drip & Sprinkler)
            </h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="index.html">Home</Link>
              </li>
              <li class="active">IRRIGATION SYSTEM (Drip & Sprinkler)</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "580px" }}>
                          <img
                            src={"assets/img/irrigation/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>

                    
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content " >
                    <h4 class="booking-title">IRRIGATION SYSTEM (Drip & Sprinkler)</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Khistiz Agro Tech (KAPL) has taken initiatives to
                        Promote IRRIGATION SYSTEM (Drip & Sprinkler) on large
                        scale.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Irrigation System is the artificial process of applying
                        controlled amounts of Water to land to assist in
                        production of Crops. Irrigation helps to grow
                        Agricultural Crops, Maintain Landscapes and revegetate
                        disturbed Soils in dry areas and during periods of less
                        than average Rainfall. Irrigation also has other uses in
                        Crop production, including Frost protection, suppressing
                        weed growth in grain fields and preventing Soil
                        Consolidation. In Contrast, Agriculture that relies only
                        on direct rainfall is referred to as Rainfed. Irrigation
                        Systems are also used for Cooling Livestock, Dust
                        suppression, disposal of sewage and in mining.
                      </li>
                      {/* <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer the Training to
                        Farmers in the Both Irrigation System for the help to
                        farmers with minimum investments and proper management,
                        it can be made in to a profitable venture for them. The
                        main focus of the KAPL is on creating a demonstration
                        effect through individual where success of one farmer
                        could motivate others to take up the Whole system. We
                        offer Complete consultation to complete Modern
                        Technology, Management, use and Benefits of Irrigation
                        System. We also offer to farmers on training and also
                        promote them towards Irrigation System (Drip and
                        Sprinkler).
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                      <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>DRIP IRRIGATION: Drip
                        (or micro) irrigation, also known as trickle irrigation,
                        functions as its name suggests. In this system water
                        falls drop by drop just at the position of roots. Water
                        is delivered at or near the root zone of plants, drop by
                        drop. This method can be the most water-efficient method
                        of irrigation, if managed properly, evaporation and
                        runoff are minimized. The field water efficiency of drip
                        irrigation is typically in the range of 80 to 90 percent
                        when managed correctly.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>SPRINKLER IRRIGATION: In
                        Sprinkler or Overhead Irrigation, water is piped to one
                        or more central locations within the field and
                        distributed by Overhead High-pressure Sprinkler or Guns.
                        A system using Sprinklers, Spray or Gun mounted overhead
                        on permanently installed risers is often referred to as
                        a Solid- set Irrigation System.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer the Training to
                        Farmers in the Both Irrigation System for the help to
                        farmers with minimum investments and proper management,
                        it can be made in to a profitable venture for them. The
                        main focus of the KAPL is on creating a demonstration
                        effect through individual where success of one farmer
                        could motivate others to take up the Whole system. We
                        offer Complete consultation to complete Modern
                        Technology, Management, use and Benefits of Irrigation
                        System. We also offer to farmers on training and also
                        promote them towards Irrigation System (Drip and
                        Sprinkler).
                      </li>
                    </ul>
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-4" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default IrrigationSystem;
