import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Functions from "./components/Functions";
import OurStructure from "./pages/OurStructure";
import WhoWeAre from "./pages/WhoWeAre";
import OurDevelopmentalFunction from "./pages/OurDevelopmentalFunction";
import RainbowAgroPark from "./pages/RainbowAgroPark";
import BiofertilizerManufacturing from "./pages/BiofertilizerManufacturing";
import VermicompostManufacturing from "./pages/VermicompostManufacturing";
import MushroomUnit from "./pages/MushroomUnit";
import PoultryUnit from "./pages/PoultryUnit";
import BaterUnit from "./pages/BaterUnit";
import GoateryUnit from "./pages/GoateryUnit";
import HorticultureUnit from "./pages/HorticultureUnit";
import FisheriesUnit from "./pages/FisheriesUnit";
import BioflocUnit from "./pages/BioflocUnit";
import OrganicFarming from "./pages/OrganicFarming";
import OrganicVegetableFarming from "./pages/OrganicVegetableFarming";
import StraberryProductionUnit from "./pages/StraberryProductionUnit";
import PolyhouseUnit from "./pages/PolyhouseUnit";
import AzollaUnit from "./pages/AzollaUnit";
import IrrigationSystem from "./pages/IrrigationSystem";
import IntegratedFarming from "./pages/IntegratedFarming";
import FposProject from "./pages/FposProject";
import TribalDevelopmentFund from "./pages/TribalDevelopmentFund";
import WatershedDevelopmentFund from "./pages/WatershedDevelopmentFund";
import OffFarmDevelopment from "./pages/OffFarmDevelopment";
import ScrollTop from "./components/ScrollTop";
import { ProductBasket } from "./pages/ProductBasket";
import MediaGallery from "./pages/MediaGallery";
import Video from "./pages/Video";


function App() {
  return (
    <Router>
      <ScrollTop />
      <div>
        {/* Navbar */}
        <Navbar />
        
        {/* Main Content */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/mediaGallery" element={<MediaGallery />} />
            <Route path="/video" element={<Video />} />
            <Route path = "/about" element = {<About/>}/>
            <Route path = "/ourstructure" element = {<OurStructure/>}/>
            <Route path = "/whoweare" element = {<WhoWeAre/>}/>
            <Route path = "/contact" element = {<Contact/>}/>
            <Route path = "/products" element = {<ProductBasket/>}/>

            <Route path = "/functions" element = {<Functions/>}/>
            <Route path = "/ourdevelopmentfunction" element = {<OurDevelopmentalFunction/>}/>
            <Route path = "/rainbowagropark" element = {<RainbowAgroPark/>}/>
            <Route path = "/BiofertilizerManufacturing" element = {<BiofertilizerManufacturing/>}/>
            <Route path = "/Vermicompost" element = {<VermicompostManufacturing/>}/>
            <Route path = "/MushroomUnit" element = {<MushroomUnit/>}/>
            <Route path = "/PoulitryUnit" element = {<PoultryUnit/>}/>
            <Route path = "/BaterUnit" element = {<BaterUnit/>}/>
            <Route path = "/goateryUnit" element = {<GoateryUnit/>}/>
            <Route path = "/horticultureUnit" element = {<HorticultureUnit/>}/>
            <Route path = "/fisherUnit" element = {<FisheriesUnit/>}/>
            <Route path = "/biofloc" element = {<BioflocUnit/>}/>
            <Route path = "/organicFarming" element = {<OrganicFarming/>}/>
            <Route path = "/organicVegitable" element = {<OrganicVegetableFarming/>}/>
            <Route path = "/StraberryProduction" element = {<StraberryProductionUnit/>}/>
            <Route path = "/polyhouse" element = {<PolyhouseUnit/>}/>
            <Route path = "/azollaUnit" element = {<AzollaUnit/>}/>
            <Route path = "/IrrigationSystem" element = {<IrrigationSystem/>}/>
            <Route path = "/IntegratedFarming" element = {<IntegratedFarming/>}/>
            <Route path = "/fposProject" element = {<FposProject/>}/>
            <Route path = "/tribalDevelopmentFund" element = {<TribalDevelopmentFund/>}/>
            <Route path = "/watershedDevelopment" element = {<WatershedDevelopmentFund/>}/>
            <Route path = "/offFarmDevelopment" element = {<OffFarmDevelopment/>}/>
            
            
          </Routes>
        </main>
        
        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
