import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function AzollaUnit() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/azolla/02.jpg",
    "assets/img/azolla/03.jpg",
    "assets/img/azolla/04.jpg",
    
  ];

 
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">AZOLLA unit</h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active">AZOLLA unit</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "410px" }}>
                          <img
                            src={"assets/img/azolla/01.jpg"}
                            alt={`Room`}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>

                   
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">AZOLLA unit</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The main focus of the
                        KAPL is on creating a demonstration effect through
                        individual where success of one Organic farmer could
                        motivate others to take up Organic farming.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer Complete
                        consultation to complete Modern Technology, Management,
                        use and Benefits of Azolla Cultivation.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We also offer to farmers on
                        training and also promote them towards Azolla
                        Cultivation.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                      <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Khistiz Agro Tech (KAPL) has taken initiatives to
                        Promote AZOLLA Farming on large scale.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Azolla is a Unique Freshwater Fern that is one of the
                        Fastest Growing plants on the planet due to its
                        Symbiotic relationship with a cynobacterium (Blue Green
                        Algae) called Anabaena.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Azolla’s rapid growth makes it a potentially important
                        sequester of Greenhouse Gas, Carbon dioxide which is
                        converted directly into Azolla’s biomass. This provides
                        local Livestock feed, Biofertilizer, Food and biofuel
                        wherever Azolla is Grown, so that this remarkable plant
                        has the potential to help us weather the Perfect Storm-
                        the related threats of Man-made Climate change and
                        Shortages of Food and Land as our Population passes
                        seven billion. We offer the Training to Farmers in
                        Azolla Cultivation for the help to boost Health with
                        minimum investments and proper management, it can be
                        made in to a profitable venture for small and marginal
                        farmers.
                      </li>
                    </ul>
                     
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-4" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default AzollaUnit;
