import React, { useState,useEffect } from "react";
import { Link } from 'react-router-dom'
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function OurStructure() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
    const images = [
        "assets/img/structure/02.jpg",
        "assets/img/structure/03.jpg",
      ];
    
  return (
    <>
            <main class="main">

{/* <!-- breadcrumb --> */}
<div class="site-breadcrumb" style={{background: "url(assets/img/breadcrumb/02.jpg)"}}>
    <div class="container">
        <h2 class="breadcrumb-title">Our Structure</h2>
        <ul class="breadcrumb-menu">
            <li><Link to="index.html">Home</Link></li>
            <li class="active">Our Structure</li>
        </ul>
    </div>
</div>



{/* <!-- about area --> */}
<div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "460px" }}>
                          <img
                            src={"assets/img/structure/01.jpg"}
                            alt={`Room`}
                            class="carousel-image"
                          />
                        </div>
                      
                      </div>
                    </div>
                   
                   
                  </div>
                </div>
                <div class="col-lg-4">
                
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">Our Structure</h4>
                    <ul class="listing-side-list">
                      <li  style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>
                        Our Head Office is located in Patna and we have
a presence with Manufacturing Unit at Nalanda.
We have a phenomenal district-level outreach
with 03 Project Managers engaged in
Agriculture and rural development at the district
level. Our training establishments are situated
at “Rainbow Agro Park” Nehusa, Harnaut,
Nalanda, serving the capacity building
requirements of rural Agricultural
Organizations, FPOs, Farmers and Woman
across the Bihar and outside of Bihar, besides
our own staff.
                      </li>
                      
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
{/* <!-- about area end --> */}



{/* <!-- counter area --> */}

{/* <!-- counter area end --> */}



{/* <!-- team-area --> */}

{/* <!-- team-area end --> */}



{/* <!-- testimonial area --> */}

{/* <!-- testimonial area end --> */}




{/* <!-- feature area --> */}

{/* <!-- feature area end --> */}



{/* <!-- cta-area --> */}

{/* <!-- cta-area end --> */}




{/* <!-- partner area --> */}

 {/* <!-- partner area end --> */}

 <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
</main>
    </>
  )
}

export default OurStructure
