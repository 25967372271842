import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function TribalDevelopmentFund() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/trible/02.jpg",
    "assets/img/trible/05.jpg",
  ];
  const images1 = [
    "assets/img/trible/04.jpg",
    
    "assets/img/trible/06.jpg",
    "assets/img/trible/07.jpg",
    "assets/img/trible/08.jpg",
  ];
  const images2 = [
    "assets/img/trible/10.jpg",
    "assets/img/trible/11.jpg",
    "assets/img/trible/12.jpg",
    "assets/img/trible/13.jpg",
    "assets/img/trible/14.jpg",
    "assets/img/trible/15.jpg",
    "assets/img/trible/16.jpg",
    "assets/img/trible/17.jpg",
  ];
  const images3 = [
    "assets/img/trible/19.jpg",
    "assets/img/trible/20.jpg",
    "assets/img/trible/21.jpg",
    "assets/img/trible/22.jpg",
  ];


  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">
            Tribal Development Fund(TDF) Project
            </h2>
            <ul class="breadcrumb-menu">
              <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                <Link to="/">Home</Link>
              </li>
              <li class="active">Tribal Development Fund(TDF) Project</li>
            </ul>
          </div>
        </div>
        

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "540px" }}>
                          <img
                            src={"assets/img/trible/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content ">
                    <h4 class="booking-title">Tribal Development Fund(TDF) Project</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Tribal Development Programme under TDF based on the
                        successful experience of Adivasi Development Programmes
                        of replicating the WADI (Small orchard) model across the
                        Country. The objective of the Fund to create replicable
                        models of Integrated Development of Tribal families on
                        participatory basis, through adoption of Sustainable
                        Income Generating activities and to build, strengthen
                        Tribal institutions which enables mainstreaming the
                        communities by improving their Socio- Economic status.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>In this connection, KAT has itself 03 TDF project in
                        Bihar. With the financial assistance of NABARD
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
        {/* <!-- room-single end --> */}
        <div style={{ marginTop: "-100px" }} class="room-single py-120 mt-100">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
              <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">1. Implementation of Integrated TDF Programme at
                    Dighalbank block of Kishanganj District</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>01st Year we have to
                        work with only 250 beneficiaries. So, in this connection
                        we had visited to member by our Project coordinator is
                        410 approx. After the survey we had selected only 250
                        members in 01st phase and rest in 02nd phase as
                        beneficiaries for the plantation work.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>During this project we
                        have to give 200 Solar Latern to beneficiaries.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Also a programme on Clean
                        India Mission has also completed by our Team and WADI
                        beneficiaries.
                      </li>
                     
                      
                    </ul>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "540px" }}>
                          <img
                            src={"assets/img/trible/03.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                        
                      </div>
                    </div>

                    
                  </div>
                </div>
                
              </div>
              <div class="listing-item">
                      <ul class="listing-side-list">
                     
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>NABARD has sanctioned for Financial Assistance for the
                        Implementation of Integrated Tribal Development
                        Programme (TDF) at Dighalbank block of Kishanganj
                        District, Bihar in year 2016-2017.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Establishment of an office at Project area Dighalbank
                        block of Kishanganj district in June 2016 for the smooth
                        work.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We had established 60 Goatry
                        unit to 60 landless WADI families under Micro
                        Enterprises Development for their Income Generation.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Under IGA activity, we had
                        done Intercropping (Ground Nuts, Organic Vegetables
                        etc.) with 350 WADI families.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We had completed work of
                        plantation and all the tribal families has approached us
                        to sow only Mangoes tree in their field in place of
                        Litchi and Guava.
                      </li>
                      
                     
                    </ul>
                    
                    </div>
            </div>
          </div>
        </div>
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images1.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images1[photoIndex]} // Set the main image
            nextSrc={images1[(photoIndex + 1) % images1.length]} // Next image
            prevSrc={images1[(photoIndex + images1.length - 1) % images1.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images1.length - 1) % images1.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images1.length)
            }
          />
        )}
        <div style={{ marginTop: "-100px" }} class="room-single py-120 mt-100">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "500px" }}>
                          <img
                            src={"assets/img/trible/09.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>

                    
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">2. Revival of Integrated Tribal Programme under TDF at
                    Banka district</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Distributed more than
                        30 Pumpset and 04 samarsable pump between the
                        Beneficiaries on PTDC committee level under Water
                        Resources.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>With the Govt.
                        Convergence, renovated 50 CHAPAKAL for WADI families.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Completed 15 days training
                        of 775 progressive WADI beneficiaries for Capacity
                        Building on “Tasar production and Organic Horticulture
                        Plantation”
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Completed 04 Exposure Visit
                        of 600 beneficiaries for Cocoon production Centre.
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>NABARD has sanctioned for Financial Assistance for the
                        Revival of Integrated Tribal Development Programme (TDF)
                        at Katoria, Chanan and Baunsi block of Banka District,
                        Bihar in year 2018-2019
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>KAT is working in these 03 Blocks with 1500 tribal
                        Families in 1500 acre on “Tasar Silk production and
                        Marketing”. For the project success, we establish an
                        office in Katoria block. Also established a nursery in
                        02 acre for plantation and hardening the plants. We had
                        sapling of more than 500000 (Five Lakhs) plants of Arjun
                        and more than 20000 Mangoes Tree in the Nursery.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Distributed Vegetables Seed, Sesame Seed etc. between
                        beneficiaries for the Intercropping.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Total 04 Ponds has made by KAT under Water Resource
                        Development.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>KAT had organized 02 Human
                        Health Camp and 02 Animal Health Camp for WADI
                        beneficiaries and their Animals.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Under COVID-19 the
                        organization had distributed Dailey staples, Hygine Kit
                        and Mask more than 600 WADI families.
                      </li>
                      
                    </ul>
                      
                    </div>
            </div>
          </div>
        </div>

        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images2.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images2[photoIndex]} // Set the main image
            nextSrc={images2[(photoIndex + 1) % images2.length]} // Next image
            prevSrc={images2[(photoIndex + images2.length - 1) % images2.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images2.length - 1) % images2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images2.length)
            }
          />
        )}
        <div style={{ marginTop: "-100px" }} class="room-single py-120 mt-100">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
              <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title"> 3. Integrated Tribal Programme under TDF at Banka
                    district</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>DUnder this project we
                        have to completed plantation of: MANGOES (Amrapali &
                        Malda), LEMON (Piti Variety) and Jackfruit in 450 Acre
                        and bordering with Arjuna, Sagwan and Moringa (Sahjan)
                        Plants. Also distributed Vegetables Seed for Inter
                        cropping.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>In this Connection, we had
                        completed the plantation in 225 Acre for the 01st Phase.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Under the water
                        conservation, also make 02 New ponds in the project Area
                        for WADI beneficiaries.
                      </li>
                     
                    </ul>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images"style={{height: "490px" }}>
                          <img
                            src={"assets/img/trible/18.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                      
                      </div>
                    </div>

                    
                  </div>
                </div>
               
              </div>
              <div class="listing-item">
              <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>NABARD has sanctioned for Financial Assistance for the
                        Implementation of Integrated Tribal Development
                        Programme (TDF) at Chanan block of Banka District, in
                        year 2020-2021
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>KAT is running this project in 15 Villages of Chanan
                        block of Banka District with 500 tribal Families in 450
                        acres on “Horticulture Plantation” and 50 of Goatery
                        Unit with 50 Landless WADI Families.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>During this Pandemic COVID
                        19, we had distributed daily staples and hygiene kits
                        between Beneficiaries.
                      </li>
                      
                     
                    </ul>
                     
                    </div>
            </div>
          </div>
        </div>

        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images3.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images3[photoIndex]} // Set the main image
            nextSrc={images3[(photoIndex + 1) % images3.length]} // Next image
            prevSrc={images3[(photoIndex + images3.length - 1) % images3.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images3.length - 1) % images3.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images3.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default TribalDevelopmentFund;
