import React, { useState } from "react";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS
import { Link } from "react-router-dom";

function Gallery() {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Array of image paths for the gallery
  const images = [
    "assets/img/gallery/01.jpg",
    "assets/img/gallery/02.jpg",
    "assets/img/gallery/03.jpg",
    "assets/img/gallery/04.jpg",
    "assets/img/gallery/05.jpg",
    "assets/img/gallery/06.jpg",
    "assets/img/gallery/07.jpg",
    "assets/img/gallery/08.jpg",
    "assets/img/gallery/09.jpg",
    "assets/img/gallery/10.jpg",
    "assets/img/gallery/11.jpg",
    "assets/img/gallery/12.jpg",
    "assets/img/gallery/13.jpg",
    "assets/img/gallery/14.jpg",
    "assets/img/gallery/15.jpg",
    "assets/img/gallery/16.jpg",
    "assets/img/gallery/17.jpg",
    "assets/img/gallery/18.jpg",
    "assets/img/gallery/19.jpg",
    "assets/img/gallery/20.jpg",
    "assets/img/gallery/21.jpg",
    "assets/img/gallery/22.jpg",
    "assets/img/gallery/23.jpg",
    "assets/img/gallery/24.jpg",
    "assets/img/gallery/25.jpg",
    "assets/img/gallery/26.jpg",
    "assets/img/gallery/27.jpg",
    "assets/img/gallery/28.jpg",
    "assets/img/gallery/29.jpg",
    "assets/img/gallery/30.jpg",
    "assets/img/gallery/31.jpg",
    "assets/img/gallery/32.jpg",
    "assets/img/gallery/33.jpg",
    "assets/img/gallery/34.jpg",
    "assets/img/gallery/35.jpg",
    "assets/img/gallery/36.jpg",
   
  ];

  return (
    <main className="main">
      {/* breadcrumb */}
      <div
        className="site-breadcrumb"
        style={{
          background: "url('assets/img/breadcrumb/02.jpg')",
        }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">Gallery</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">Gallery</li>
          </ul>
        </div>
      </div>

      {/* gallery-area */}
      <div className="gallery-area py-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay=".25s"
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Gallery</span>
                <h2 className="site-title">Khistiz Agro Photo Gallery</h2>
              </div>
            </div>
          </div>
          <div className="row popup-gallery">
            {images.map((image, index) => (
              <div className="col-md-4" key={index}>
                <div className="gallery-item">
                  <div className="gallery-img">
                    <img
                      src={image}
                      alt={`Gallery ${index + 1}`}
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "100%", // Makes image stretch to the container's width
                        height: "250px", // Fixed height
                        objectFit: "cover", // Ensures the image covers the space properly
                      }}
                    />
                  </div>
                  <div className="gallery-content">
                    <Link
                      className="popup-img gallery-link"
                      to={image}
                      onClick={(e) => {
                        e.preventDefault();
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    >
                      <i className="far fa-plus"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* gallery-area end */}

      {/* Lightbox for Viewing Images */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]} // Set the main image
          nextSrc={images[(photoIndex + 1) % images.length]} // Next image
          prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
          onCloseRequest={() => setIsOpen(false)} // Close the lightbox
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </main>
  );
}

export default Gallery;
