import React, { useState } from "react";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomePageBody() {
    const blogs = [
        { id: 1, imgSrc: "assets/img/developmental/03.jpg", title: "Our Development Functions",link:"/ourdevelopmentfunction", delay: ".25s" },
        { id: 2, imgSrc: "assets/img/rainbow/01.jpg", title: "Rainbow Agro Park", link:"/rainbowagropark",delay: ".50s" },
        { id: 3, imgSrc: "assets/img/biofertilizer/04.jpg", title: "Biofertilizer Manufacturing & marketing",link:"/BiofertilizerManufacturing", delay: ".75s" },
        { id: 4, imgSrc: "assets/img/vermincompost/01.jpg", title: "Vermicompost Manufacturing ",link:"/Vermicompost", delay: ".25s" },
        { id: 5, imgSrc: "assets/img/mashroom/01.jpg", title: "Mushroom Unit", link:"/MushroomUnit",delay: ".50s" },
        { id: 6, imgSrc: "assets/img/poultry/01.jpg", title: "PoulitryUnit",link:"/PoulitryUnit", delay: ".75s" },
        { id: 7, imgSrc: "assets/img/bater/01.jpg", title: "Bater (Quil) Unit",link:"/BaterUnit", delay: ".25s" },
        { id: 8, imgSrc: "assets/img/horticulture/01.jpg", title: "High-Density Horticulture", link:"/horticultureUnit",delay: ".50s" },
        { id: 9, imgSrc: "assets/img/fisher/01.jpg", title: "Fisheries Unit",link:"/fisherUnit", delay: ".75s" },
        { id: 10, imgSrc: "assets/img/biofolk/01.jpg", title: "Biofloc Unit",link:"/biofloc", delay: ".25s" },
        { id: 11, imgSrc: "assets/img/organic/01.jpg", title: "Organic Farming System", link:"/organicFarming",delay: ".50s" },
        { id: 12, imgSrc: "assets/img/organicveg/01.jpg", title: "Organic Vegitable Farming",link:"/organicVegitable", delay: ".75s" },
        { id: 13, imgSrc: "assets/img/stawberry/01.jpg", title: "Straberry Production Unit",link:"/StraberryProduction", delay: ".25s" },
        { id: 14, imgSrc: "assets/img/polyhouse/01.jpg", title: "Polyhouse (Greenhouse) Unit", link:"/polyhouse",delay: ".50s" },
        { id: 15, imgSrc: "assets/img/azolla/01.jpg", title: "Azolla Unit",link:"/azollaUnit", delay: ".75s" },
        { id: 16, imgSrc: "assets/img/goat/01.jpg", title: "Goatery Unit",link:"/goateryUnit", delay: ".25s" },
        { id: 17, imgSrc: "assets/img/irrigation/01.jpg", title: "Irrigation System (Drip & Sprinkler)", link:"/IrrigationSystem",delay: ".50s" },
        { id: 18, imgSrc: "assets/img/integrated/01.jpg", title: "Integrated Farming",link:"/IntegratedFarming", delay: ".75s" },
        { id: 19, imgSrc: "assets/img/fpos/01.jpg", title: "FPOs Projects (SAMRIDHI)",link:"/fposProject", delay: ".25s" },
        { id: 20, imgSrc: "assets/img/trible/01.jpg", title: "Tribal Development Fund", link:"/tribalDevelopmentFund",delay: ".50s" },
        { id: 21, imgSrc: "assets/img/watershed/01.jpg", title: "Watershed Development Fund",link:"/watershedDevelopment", delay: ".75s" },
        { id: 22, imgSrc: "assets/img/offfarm/01.jpg", title: "Off Farm Development",link:"/offFarmDevelopment", delay: ".25s" },
        
    ];
  const backgroundImage1 = "assets/img/hero/hero-10.jpg";
  const backgroundImages = "assets/img/video/02.png";
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Array of image paths for the gallery
  const images = [
   
    "assets/img/gallery/37.jpg",
    "assets/img/gallery/38.jpg",
    "assets/img/gallery/39.jpg",
    "assets/img/gallery/40.jpg",
    "assets/img/gallery/41.jpg",
    "assets/img/gallery/42.jpg",
    "assets/img/gallery/43.jpg",
    "assets/img/gallery/44.jpg",
    "assets/img/gallery/45.jpg",
    "assets/img/gallery/46.jpg",
    "assets/img/gallery/47.jpg",
    "assets/img/gallery/48.jpg",
    "assets/img/gallery/49.jpg",
    "assets/img/gallery/50.jpg",
    "assets/img/gallery/51.jpg",
    "assets/img/gallery/52.jpg",
    "assets/img/gallery/53.jpg",
    "assets/img/gallery/54.jpg",
    "assets/img/gallery/55.jpg",
    "assets/img/gallery/56.jpg",
    "assets/img/gallery/57.jpg",
    "assets/img/gallery/58.jpg",
   
    
  
  
  ];
  const backgroundImage = [
    "assets/img/hero/01.jpg",
    "assets/img/hero/02.jpg",
    "assets/img/hero/04.jpg"
  
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <main class="main">
        {/* <!-- hero area --> */}
        {/* <div class="hero-section">
          <div
            class="hero-single"
            style={{ backgroundImage: `url(${backgroundImage1})` }}
          >
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-12 mx-auto">
                  <div class="hero-content text-center">
                    <div class="hero-content-wrapper">
                    <h1 class="hero-title">Dare to Be Different</h1>
                    <p>12 years of empowering farmers and rural communities organically</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="hero-section">
      <Slider {...settings}>
        {backgroundImage.map((image, index) => (
          <div key={index}>
            <div
              className="hero-single"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                height: "680px",
                // paddingBottom:"170px"
              }}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12 mx-auto">
                    <div className="hero-content text-center">
                      <div className="hero-content-wrapper">
                        <h1 className="hero-title">Dare to Be Different</h1>
                        <p>12 years of empowering farmers and rural communities organically</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
        {/* <!-- hero area end --> */}

        {/* <!-- search area --> */}

        {/* <!-- search area end --> */}

        {/* <!-- feature area --> */}
        <div className="feature-area pt-120 pb-80">
      <div className="container">
        <div className="feature-wrapper">
          <div className="row">
            {/* About Us Card */}
            <div className="col-md-6 col-lg-4">
              <Link to="/about" className="feature-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".25s">
                <div className="feature-icon">
                  <i className="flaticon-global-1"></i>
                </div>
                <h4 className="feature-title">About Us</h4>
                <p>
                  Khistiz Agro Tech (KAPL) celebrates 12 years of empowering farmers and rural women with sustainable impact.
                </p>
              </Link>
            </div>

            {/* Who We Are Card */}
            <div className="col-md-6 col-lg-4">
              <Link to="/whoweare" className="feature-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".50s">
                <div className="feature-icon">
                  <i className="flaticon-medal"></i>
                </div>
                <h4 className="feature-title">Who We Are?</h4>
                <p>
                  KAPL, since 2009, drives rural prosperity through agriculture, women empowerment, and skill development.
                </p>
              </Link>
            </div>

            {/* Our Structure Card */}
            <div className="col-md-6 col-lg-4">
              <Link to="/ourstructure" className="feature-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".75s">
                <div className="feature-icon">
                  <i className="flaticon-customer-service"></i>
                </div>
                <h4 className="feature-title">Our Structure</h4>
                <p>
                  KAPL, based in Patna with a Nalanda unit, empowers farmers and rural communities through training at Rainbow Agro Park.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
        {/* <!-- feature area end --> */}

        <div style={{ marginTop: "-150px" }} class="blog-area py-120">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-6 mx-auto wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay=".25s"
              >
                <div class="site-heading text-center">
                  {/* <span class="site-title-tagline">Function</span> */}
                  <h2 class="site-title">Our Functions</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {blogs.map((blog, index) => (
                <div className="col-md-6 col-lg-4" key={blog.id}>
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay={blog.delay}
                  >
                   <div className="blog-item-img" style={{ height: "250px", overflow: "hidden" }}>
  <img
    src={blog.imgSrc}
    alt={`Blog ${index + 1}`}
    style={{ height: "100%", width: "100%", objectFit: "cover" }}
  />
</div>
                    <div className="blog-item-info">
                      <h4 className="blog-title mt-4" >
                        <Link to={blog.link}>{blog.title}</Link>
                      </h4>
                      <Link className="theme-btn mt-3" to={blog.link}>
                        Read More <i className="far fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <!-- destination area --> */}
      
        {/* <!-- destination area end --> */}

        {/* <!-- counter area --> */}
        <div class="counter-area pt-80 pb-80">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                  <div class="icon">
                    <i class="fal fa-check-circle"></i>
                  </div>
                  <div class="counter-content">
                    <div class="counter-number">
                      <span
                        class="counter"
                        data-count="+"
                        data-to="120"
                        data-speed="3000"
                      >
                        120
                      </span>
                      <span class="counter-sign">k</span>
                    </div>
                    <h6 class="title">Booking Done</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                  <div class="icon">
                    <i class="fal fa-earth-americas"></i>
                  </div>
                  <div class="counter-content">
                    <div class="counter-number">
                      <span
                        class="counter"
                        data-count="+"
                        data-to="200"
                        data-speed="3000"
                      >
                        200
                      </span>
                      <span class="counter-sign">+</span>
                    </div>
                    <h6 class="title">Our Destination</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                  <div class="icon">
                    <i class="fal fa-smile"></i>
                  </div>
                  <div class="counter-content">
                    <div class="counter-number">
                      <span
                        class="counter"
                        data-count="+"
                        data-to="40"
                        data-speed="3000"
                      >
                        40
                      </span>
                      <span class="counter-sign">k</span>
                    </div>
                    <h6 class="title">Happy Clients</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="counter-box">
                  <div class="icon">
                    <i class="fal fa-users"></i>
                  </div>
                  <div class="counter-content">
                    <div class="counter-number">
                      <span
                        class="counter"
                        data-count="+"
                        data-to="180"
                        data-speed="3000"
                      >
                        180
                      </span>
                      <span class="counter-sign">+</span>
                    </div>
                    <h6 class="title">Our Partners</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        {/* <!-- video area --> */}
        <div class="video-area">
          <div class="container-fluid px-0">
            <div
              class="video-content"
              style={{ backgroundImage: `url(${backgroundImages})` }}
            >
              <div class="row align-items-center">
                <div class="col-lg-12">
                  <div class="video-wrapper">
                    <Link
                      class="play-btn popup-youtube"
                      to="https://www.youtube.com/watch?v=F1xkHZoW_Ec"
                    >
                      <i class="fas fa-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- video area end --> */}

        {/* <!-- car area --> */}
       
        {/* <!-- car area end --> */}

        {/* <!-- download area --> */}
        {/* <div class="download-area pt-120">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div
                  class="download-img wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".25s"
                >
                  <img src="assets/img/download/01.png" alt="" />
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="download-content wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay=".25s"
                >
                  <div class="site-heading mb-0">
                    <span class="site-title-tagline">Download</span>
                    <h2 class="site-title">
                      MyTrip Android and IOS App is Available! Download Now
                    </h2>
                    <p>
                      There are many variations of passages contrary to popular
                      belief available the but the majority have suffered
                      alteration in some form by injected humour.
                    </p>
                    <ul class="download-feature">
                      <li>
                        <i class="far fa-check"></i> At vero accusamus iusto
                        odio ducimus blanditii
                      </li>
                      <li>
                        <i class="far fa-check"></i> Sed perspiciatis unde omnis
                        iste natu sit voluptatem
                      </li>
                      <li>
                        <i class="far fa-check"></i> Nor again is anyone who
                        loves pursues desires
                      </li>
                    </ul>
                    <div class="download-link">
                      <Link to="#">
                        <img src="assets/img/download/google-play.png" alt="" />
                      </Link>
                      <Link to="#">
                        <img src="assets/img/download/app-store.png" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- download area end --> */}

        {/* <!-- partner area --> */}
        {/* <div class="partner-area pt-100 pb-80">
          <div class="container">
            <div class="partner-wrapper partner-slider owl-carousel owl-theme">
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/02.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/02.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/02.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
            </div>
          </div>
        </div> */}
        {/* <!-- partner area end --> */}

        {/* <!-- testimonial area --> */}

        {/* <!-- testimonial area end --> */}

        {/* <!-- blog area --> */}

        {/* <!-- blog area end --> */}
        
      {/* breadcrumb */}
      

      {/* gallery-area */}
      {/* <div className="gallery-area py-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay=".25s"
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Media Gallery</span>
                <h2 className="site-title">Khistiz Agro Media Gallery</h2>
              </div>
            </div>
          </div>
          <div className="row popup-gallery">
            {images.map((image, index) => (
              <div className="col-md-4" key={index}>
                <div className="gallery-item">
                  <div className="gallery-img">
                    <img
                      src={image}
                      alt={`Gallery ${index + 1}`}
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "100%", // Makes image stretch to the container's width
                        height: "250px", // Fixed height
                        objectFit: "cover", // Ensures the image covers the space properly
                      }}
                    />
                  </div>
                  <div className="gallery-content">
                    <Link
                      className="popup-img gallery-link"
                      to={image}
                      onClick={(e) => {
                        e.preventDefault();
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    >
                      <i className="far fa-plus"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="gallery-area py-120">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 mx-auto wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay=".25s"
          >
            <div className="site-heading text-center">
              <span className="site-title-tagline">Media Gallery</span>
              <h2 className="site-title">Khistiz Agro Media Gallery</h2>
            </div>
          </div>
        </div>
        <div className="row popup-gallery">
          {images.map((image, index) => (
            <div className="col-md-4" key={index}>
              <div className="gallery-item special-gallery-item">
                <div className="gallery-img">
                  <img
                    src={image}
                    alt={`Gallery ${index + 1}`}
                    onClick={() => {
                      setPhotoIndex(index);
                      setIsOpen(true);
                    }}
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="gallery-content">
                  <Link
                    className="popup-img gallery-link"
                    to={image}
                    onClick={(e) => {
                      e.preventDefault();
                      setPhotoIndex(index);
                      setIsOpen(true);
                    }}
                  >
                    <i className="far fa-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
      {/* gallery-area end */}

      {/* Lightbox for Viewing Images */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]} // Set the main image
          nextSrc={images[(photoIndex + 1) % images.length]} // Next image
          prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
          onCloseRequest={() => setIsOpen(false)} // Close the lightbox
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
  
      </main>
    </>
  );
}

export default HomePageBody;
