import React, { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function OffFarmDevelopment() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/offfarm/02.jpg",
    "assets/img/offfarm/03.jpg",
    "assets/img/offfarm/04.jpg",
    "assets/img/offfarm/05.jpg",
    "assets/img/offfarm/06.jpg",

   
  ];
  const images1 = [
    "assets/img/offfarm/07.jpg",
    "assets/img/offfarm/08.jpg",
    "assets/img/offfarm/09.jpg",
    "assets/img/offfarm/10.jpg",
    "assets/img/offfarm/11.jpg",
   
  ];
  const images2 = [
   "assets/img/offfarm/12.jpg",
    "assets/img/offfarm/13.jpg",
  ];

 

 
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">
              Off Farm Development Initiatives PROJECT
            </h2>
            <ul class="breadcrumb-menu">
              <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                <a href="index.html">Home</a>
              </li>
              <li class="active">Off Farm Development Initiatives PROJECT</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}

        <div style={{ marginTop: "-80px" }} class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images">
                          <img
                            src={"assets/img/offfarm/01.jpg"}
                            alt={`Room`}
                            class="carousel-image"
                          />
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">Nalanda Jaanta Sattu (SHGs )</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Nalanda Jaanta Sattu
                        (SHGs ) - For the development of rural weak and
                        dependable women KAT has dedicatedly work for their
                        self- dependence under a roof Livelihood Enterprise
                        Development Programme (LEDP) called ”NALANDA JAANTA
                        SATTU” here they are making Sattu by a traditional
                        method of stone hand grinder and develop their
                        livelihood within their permission. This product also
                        giving benefit to end user from the hazard of Sattu
                        which is generally made by automatic machinery grinder.
                        We had total number of 2200 Women in 05 different
                        districts of Bihar. The women members
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
        <div style={{ marginTop: "-100px" }} class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">
                      Khistiz Puwal Painting (SHGs )
                    </h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The pro poor rural
                        women are the members of SHGs and earlier somehow
                        connected with Cereal Straw Craft promotion at minor
                        level from the locally involved in Modanganj block of
                        Jehanabad with the support of NABARD, we Khistiz Agro
                        Tech give training to SHGs members for the process and
                        needed financial support with equipped training under
                        the LEDP program
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We had total number of
                        110 Women in Jehanabad district of Bihar. The women
                        members earn Rs. 400.00 to 800.00 for 01 painting as per
                        their SIZE and IMAGE. This income help them in the
                        financial support to their family.
                      </li>
                      
                    </ul>
                  
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height:"540px"}}>
                          <img
                            src={"assets/img/offfarm/07.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div>
              <ul class="listing-side-list">
                     
                       
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>  Khistiz Puwal Painting (SHGs ) - For the development of
                        rural weak and dependable women KAT has dedicatedly work
                        for their self-dependence under a roof Livelihood
                        Enterprise Development Programme (LEDP) called ”CEREAL
                        STRAW (PUWAL) PAINTING”.
                      </li>
                    </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images1.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images1[photoIndex]} // Set the main image
            nextSrc={images1[(photoIndex + 1) % images1.length]} // Next image
            prevSrc={images1[(photoIndex + images1.length - 1) % images1.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images1.length - 1) % images1.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images1.length)
            }
          />
        )}
        <div style={{ marginTop: "-100px" }} class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height:"600px"}}>
                          <img
                            src={"assets/img/offfarm/12.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content ">
                    <h4 class="booking-title">
                      AGARBATTI making Project (SHGs)
                    </h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>On 05th July 2016 Day:
                        Tuesay, This programme was inaugurated by Mr. Sanjeev
                        Kumar, Hon’able DDM NABARD, Jamui. Speaking on the
                        occasion, DDM-NABARD, (Jamui) said that NABARD has been
                        promoting the Self Help Group – Bank Linkage programme
                        throughout the country from last more than two decades.
                        He stated that poverty alleviation through livelihood
                        creation is one of the stated goals of this microfinance
                        movement. NABARD has sanctioned this LEDP to Khistiz
                        Agro Tech (KAPL), Patna wherein 90 members from matured
                        Self Help Groups will be trained to prepare Agarbatti
                        with Machine. For the development of rural weak and
                        dependable women KAT has dedicatedly work for their
                        self-dependence under a roof Livelihood Enterprise
                        Development Programme (LEDP) called ”AGARBATTI MAKING”.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images2.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images2[photoIndex]} // Set the main image
            nextSrc={images2[(photoIndex + 1) % images2.length]} // Next image
            prevSrc={images2[(photoIndex + images2.length - 1) % images2.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images2.length - 1) % images2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images2.length)
            }
          />
        )}
        {/* <!-- room-single end --> */}
      </main>
    </>
  );
}

export default OffFarmDevelopment;
