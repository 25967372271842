import React, { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function StraberryProductionUnit() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/stawberry/02.jpg",
    "assets/img/stawberry/03.jpg",
    "assets/img/stawberry/04.jpg",
    
  ];

  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">Strawberry production Unit</h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active">Strawberry production Unit</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "520px" }}>
                          <img
                            src={"assets/img/stawberry/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>

                   
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">Strawberry production Unit</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer the Training
                        to Farmers in Strawberry Production for the help to
                        boost Health with proper management and Doubling Income,
                        it can be made in to a profitable venture for small and
                        marginal farmers.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The main focus of the KAPL
                        is on creating a demonstration effect through individual
                        where success of one farmer could motivate others to
                        take up Strawberry farming.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer Complete
                        consultation from Crop selection, Selection of Organic
                        Fertilizers, to complete Modern Technology and
                        Management of Strawberry Farming.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
              <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Khistiz Agro Tech (KAPL) has taken initiatives to
                        Produce Strawberry on demonstration purpose.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Strawberry (Fragaria vesca) is an important fruit crop
                        of India and its commercial production is possible in
                        temperate and sub-tropical areas of the country.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i> KAPL has also trained to Farmers in Strawberry
                        Cultivation Technology and develops a new and modern era
                        of Farming model in Agriculture system for the Farmers
                        of India.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Strawberry has advantages of easy propagation, early
                        maturity and high yield with 5-9% sugar. To boost its
                        production there is a need to develop infra-structure
                        facilities for transport of produce to primary markets
                        as the fruit is highly perishable. Processing facilities
                        in the major producing states have to be made for value
                        addition.
                      </li>
                    </ul>
                      
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-4" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default StraberryProductionUnit;
