import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function BaterUnit() {
  const images = [
    "assets/img/bater/02.jpg",
    "assets/img/bater/03.jpg",
    "assets/img/bater/04.jpg",
    "assets/img/bater/05.jpg",

    
  ];
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);

 
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">Bater (Quil) Unit </h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active">Bater (Quil) Unit </li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "560px" }}>
                          <img
                            src={"assets/img/bater/01.jpg"}
                            alt={`Room`}
                            class="carousel-image"
                          />
                        </div>
                      </div>
                    </div>

                    
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content ">
                    <h5 class="booking-title">
                      Quails are smaller sized Poultry Birds and It’s very easy
                      to handle them. The main benefits of starting Quail
                      Farming business are:-
                    </h5>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.5' }}>
                        <i class="far fa-check"></i>Quails are smaller
                        sized poultry birds, so they can be raised within small
                        place.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.5' }}>
                        <i class="far fa-check"></i>Feeding cost of Quail is
                        comparatively lower than Chickens or other poultry
                        birds. 3. Disease are less in Quail and they are very
                        Hardy.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.5' }}>
                        <i class="far fa-check"></i>Quail grow very Fast and
                        Gain maturity faster than any other Poultry Birds.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.5' }}>
                        <i class="far fa-check"></i>Meat and Eggs of Quail are
                        very Tasty, Delicious and Nutritious. So, It’s a great
                        source of Food and Nutrition.
                      </li>

                      <li style={{ textAlign: 'justify', lineHeight: '1.5' }}>
                        <i class="far fa-check"></i>Disease are less in Quail and
                        they are very Hardy.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                      <h4 class="mb-3">Khistiz’s Bater (Quil) Unit </h4>
                      <p style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        KAT has also trained to Farmers in BATER (Quail) FARMING
                        and develops production model of IFS (Integrated Farming
                        System).{" "}
                      </p>
                      <p class="mt-3" style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        Quail egg is roughly one-fifth the size of a chicken’s
                        egg and weigh around 10 Gm. The eggshells are spotted
                        with colors ranging from White to Brown. Nutritionally,
                        the quality of these eggs is at par with that of chicken
                        eggs; rather they contain less Cholesterol.{" "}
                      </p>
                      <p class="mt-3" style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        Under this Bater unit of KAPL Quail birds are kept. For
                        the Promotion of livelihood diversification and raise
                        the income level by Khistiz Agro Tech (KAPL) also
                        promoted backyard poultry by distributing birds to
                        member farmers.
                      </p>
                      <p class="mt-3" style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        Bater has special medicinal value in homeopathy and a
                        particular nervous disorder.
                      </p>
                      <p class="mt-3" style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        We had also Sale Unit at our Farm. From where You can
                        easily purchase the fresh Bater and Eggs.
                      </p>
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        
 <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default BaterUnit;
