import React, { useRef } from 'react';

function Video() {
  const videoRefs = useRef([]);

  const handleVideoClick = (index) => {
    const video = videoRefs.current[index];
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const videoList = [
    'https://www.w3schools.com/html/mov_bbb.mp4',
    'https://www.w3schools.com/html/movie.mp4',
    'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4',
  ];

  return (
    <div className="video-container" style={{ padding: '20px' }}>
      {videoList.map((videoSrc, index) => (
        <div key={index} style={{ marginBottom: '20px', textAlign: 'center' }}>
          <video
            ref={(el) => (videoRefs.current[index] = el)}
            width="80%"
            onClick={() => handleVideoClick(index)}
            style={{ cursor: 'pointer', borderRadius: '12px' }}
            controls={false}
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p>Click the video to play/pause</p>
        </div>
      ))}
    </div>
  );
}

export default Video;
