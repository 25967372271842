import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Function.css';
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS


function WatershedDevelopmentFund() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
    const images = [
        "assets/img/watershed/02.jpg",
        "assets/img/watershed/03.jpg",
        "assets/img/watershed/04.jpg",
        "assets/img/watershed/05.jpg",
        "assets/img/watershed/06.jpg",
        "assets/img/watershed/07.jpg",
        "assets/img/watershed/08.jpg",
        "assets/img/watershed/09.jpg",
        
      ]

  
  return (
    <>
<main class="main">

{/* <!-- breadcrumb --> */}
<div class="site-breadcrumb" style={{background: "url(assets/img/breadcrumb/05.jpg)"}}>
    <div class="container">
        <h2 class="breadcrumb-title">Watershed Development Fund (WDF) PROJECT –
        BANKA (BIHAR)</h2>
        <ul class="breadcrumb-menu">
            <li style={{ textAlign: 'justify', lineHeight: '1.6' }}><Link to="/">Home</Link></li>
            <li class="active">Watershed Development Fund (WDF) PROJECT –
            BANKA (BIHAR)</li>
        </ul>
    </div>
</div>



{/* <!-- room-single --> */}
<div class="room-single py-120">
    <div class="container">
        <div class="listing-wrapper">
            <div class="row">
                <div class="col-lg-8">
                    <div class="listing-content">
                    <div class="listing-content">
      <div class="listing-slider">
        <div class="carousel-images" style={{height:"600px"}}>
          <img
            src={"assets/img/watershed/01.jpg"}
            alt={`Room`}
            class="carousel-image"
          />
        </div>
       
      </div>
    </div>
                        
                        <div class="listing-item">
                            
                        <h4 class="booking-title">Climate Proofing Programme in ASOTI NADI-II at
                        Belhar block of Banka District</h4>
                        <ul class="listing-side-list">
                            <li style={{ textAlign: 'justify', lineHeight: '1.6' }}><i class="far fa-check"></i>NABARD has sanctioned for Financial Assistance for the
Implementation of Climate Proofing Project for Asoti Nadi
– II watershed project at Belhar block of Banka District,
Bihar in year 2019-2020.</li>
                            <li style={{ textAlign: 'justify', lineHeight: '1.6' }}><i class="far fa-check"></i>Several factors make work of KHISTIZ AGRO TECH strikingly
unique. First, it believes staunchly in community-based
development and has designed programmes accordingly.
Right knowledge and right action are motivating people.
This knowledge, explained in simple terms, is understood
and disseminated from village to village. Second, the
Organisation’s strategy of empowering stakeholders with
knowledge and motivation is more result-oriented than the
subsidy and sops approach to development.</li>
                            <li style={{ textAlign: 'justify', lineHeight: '1.6' }}><i class="far fa-check"></i>The villagers from the Target Project Area are
mainly dependent on the agriculture activity. They are
very hardworking and relying mainly on the agriculture
activity. Still majority family are not able to avail the
benefit of the irrigation facility.</li>
                            
                        </ul>  

                           
                        </div>
                       
                       
                        
                       
                        
                    </div>
                </div>
                <div class="col-lg-4">
                    
                    <div class="booking-sidebar listing-side-content">
                    <h4 class="mb-3">Climate Proofing Programme in ASOTI NADI-1 at
                            Belhar block of Banka District</h4>
                            <ul class="listing-side-list">
                            <li style={{ textAlign: 'justify', lineHeight: '1.6' }}><i class="far fa-check"></i>NABARD has sanctioned for Financial Assistance for the
                            NABARD has sanctioned for Financial Assistance for the
Implementation of Climate Proofing Project for Asoti Nadi
– I watershed project at Belhar block of Banka District, in
year 2019-2020.</li>
                            <li style={{ textAlign: 'justify', lineHeight: '1.6' }}><i class="far fa-check"></i>Several factors make work of KHISTIZ AGRO TECH strikingly
unique. First, it believes staunchly in community-based
development and has designed programmes accordingly.
Right knowledge and right action are motivating people.
This knowledge, explained in simple terms, is understood
and disseminated from village to village. Second, the
Organisation’s strategy of empowering stakeholders with
knowledge and motivation is more result-oriented than the
subsidy and sops approach to development.</li>
                            <li style={{ textAlign: 'justify', lineHeight: '1.6' }}><i class="far fa-check"></i>The villagers from the Target Project Area are mainly
dependent on the agriculture activity. They are very
hardworking and relying mainly on the agriculture activity.
Still majority family are not able to avail the benefit of the
irrigation facility.</li>
<li style={{ textAlign: 'justify', lineHeight: '1.6' }}><i class="far fa-check"></i>In this connection, we had completed Renovation of 02
Pond. Completed 02 Training for the Beneficiaries and also
distributed Bio-Fertilizers towards 50 Household. Also
gave the Moong seed to Beneficiaries for the Sustainable
Farming. </li>
    
                        </ul>
                       
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- room-single end --> */}

<div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}

</main>
    </>
  )
}

export default WatershedDevelopmentFund