import React, { useState } from "react";
import { Link } from "react-router-dom";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      const response = await fetch("https://khistiz-agro-tech-server.onrender.com/send-mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage("Email sent successfully!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        setResponseMessage("Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setResponseMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/09.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Contact Us</h2>
            <ul className="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Contact Us</li>
            </ul>
          </div>
        </div>

        <div className="contact-area py-120">
          <div className="container">
            <div className="contact-wrapper">
              <div className="row">
                <div className="col-lg-4">
                  <div className="contact-content">
                    <div className="contact-info">
                      <div className="contact-info-icon">
                        <i className="fal fa-map-marker-alt"></i>
                      </div>
                      <div className="contact-info-content">
                        <h3>Office Address</h3>
                        <p>
                          Priyadarshi Nagar, D.P.S., Rupaspur Thana More, Baily
                          Road, Patna-801503(BH.)
                        </p>
                      </div>
                    </div>
                    <div className="contact-info">
                      <div className="contact-info-icon">
                        <i className="fal fa-phone"></i>
                      </div>
                      <div className="contact-info-content">
                        <h5>Call Us</h5>
                        <p>9931610419 / 8409302239</p>
                      </div>
                    </div>
                    <div className="contact-info">
                      <div className="contact-info-icon">
                        <i className="fal fa-envelope"></i>
                      </div>
                      <div className="contact-info-content">
                        <h5>Email Us</h5>
                        <p>connect2khistizagor@gmail.com</p>
                        <p>khistizagrotech@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 align-self-center">
                  <div className="contact-form">
                    <div className="contact-form-header">
                      <h2>Get In Touch</h2>
                      <p>Join us in empowering farmers and rural communities organically.</p>
                    </div>
                    <form onSubmit={handleSubmit} id="contact-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Your Name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Your Email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Your Subject"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          cols="30"
                          rows="5"
                          className="form-control"
                          placeholder="Write Your Message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <button type="submit" className="theme-btn" disabled={loading}>
                        {loading ? (
                          <>
                            Sending... <i className="fas fa-spinner fa-spin"></i>
                          </>
                        ) : (
                          <>
                            Send Message <i className="far fa-paper-plane"></i>
                          </>
                        )}
                      </button>
                      <div className="col-md-12 mt-3">
                        <div
                          className={`form-messege ${
                            responseMessage.includes("success") ? "text-success" : "text-danger"
                          }`}
                        >
                          {responseMessage}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13155.278727384342!2d85.05108125007048!3d25.604554426188756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed5645941a81df%3A0xd9cea848ecd808cc!2sDelhi%20Public%20School%20Patna%20(Junior%20Wing)!5e0!3m2!1sen!2sin!4v1737943765097!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>
      </main>
    </>
  );
}

export default Contact;
