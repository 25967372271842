import React, { useState } from "react";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS
import { Link } from "react-router-dom";


export const ProductBasket = () => {
    const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Array of image paths for the gallery
  const images = [
    "assets/img/product/01.jpg",
    "assets/img/product/02.jpg",
    "assets/img/product/03.jpg",
    "assets/img/product/04.jpg",
    "assets/img/product/05.jpg",
    "assets/img/product/06.jpg",
    "assets/img/product/08.jpg",
    "assets/img/product/09.jpg",
    "assets/img/product/10.jpg",
    "assets/img/product/11.jpg",
   
 
  ];
  const productNames = [
    "Biofertilizer Liquid",
    "Biofertilizer Powder",
    "Vermicompost Dharti Amtrit",
    "At BAU,Sabour(under PPP mode)",
    "Nalanda Jaanta Sattu",
    "Nalanda Jaanta Basan",
    "Khistiz Honey",
    "Oyester Mushroom",
    "Button Mushroom",
    "Fish",
  ];
  return (
    <main className="main">
      {/* breadcrumb */}
      <div
        className="site-breadcrumb"
        style={{
          background: "url('assets/img/breadcrumb/02.jpg')",
        }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">Products</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">Products</li>
          </ul>
        </div>
      </div>

      {/* gallery-area */}
      <div className="gallery-area py-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay=".25s"
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Products</span>
                <h2 className="site-title">Khistiz Agro Photo Products</h2>
              </div>
            </div>
          </div>
          <div className="row popup-gallery">
            {images.map((image, index) => (
              <div className="col-md-4" key={index}>
                <div className="gallery-item">
                  <div className="gallery-img">
                    <img
                      src={image}
                      alt={`Gallery ${index + 1}`}
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "100%", // Makes image stretch to the container's width
                        height: "250px", // Fixed height
                        objectFit: "cover", // Ensures the image covers the space properly
                      }}
                    />
                  </div>
                  <div className="gallery-content">
                    <Link
                      className="popup-img gallery-link"
                      to={image}
                      onClick={(e) => {
                        e.preventDefault();
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    >
                      <i className="far fa-plus"></i>
                    </Link>
                  </div>
                </div>
                <h4
  style={{
    
    
    cursor: "pointer", // Cursor pointer to indicate clickable
    // marginTop: "-40px",
    marginBottom: "15px", // Margin below the button
    display: "inline-block", // Make sure it fits content
    textAlign: "center", // Center text
    // fontSize: "18px", // Default font size
    width: "100%", // Ensure it fits within the container
  }}
  className="responsive-product-name"
>
  {productNames[index]}
</h4>

              </div>
            ))}
          </div>
        </div>
      </div>
      {/* gallery-area end */}

      {/* Lightbox for Viewing Images */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]} // Set the main image
          nextSrc={images[(photoIndex + 1) % images.length]} // Next image
          prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
          onCloseRequest={() => setIsOpen(false)} // Close the lightbox
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </main>
  )
}
