import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS


function PoultryUnit() {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/poultry/02.jpg",
    "assets/img/poultry/03.jpg",
    "assets/img/poultry/04.jpg",
    "assets/img/poultry/05.jpg",
    "assets/img/poultry/06.jpg",
    
  ];


  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">Poultry Unit</h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active">Poultry Unit</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images">
                          <img
                            src={"assets/img/poultry/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                      </div>
                    </div>

                  
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">KHISTIZ’S POULTRY UNIT</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The eggs of the
                        KARAKNATH, also dubbed “Diet Eggs”, help alleviate
                        severe Headaches, Fainting spells, Asthma and Nephritis.
                        Its meat and eggs are also believed to benefit patients
                        with Tuberculosis.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Kadaknath has special
                        medicinal value in homeopathy and a particular nervous
                        disorder.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We had also Poultry sale
                        Unit at our Farm. From where You can easily purchase the
                        fresh Chicken (Kadaknath, Banraja etc.)
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Kadaknath has special
                        medicinal value in homeopathy and a particular nervous
                        disorder.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
              <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>KAT has also trained to Farmers in POULTRY FARMING and
                        develops vermi- composting + poultry + Fisheries+ Banana
                        production model of IFS (Integrated Farming System).
                      </li>
                      
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i> KADAKNATH also called Kali Masi (Fowl having black
                          flesh), is an Indian Breed of Chicken.
                      </li>
                    </ul>
                     
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        
 <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default PoultryUnit;
