import React, { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function FisheriesUnit() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/fisher/02.jpg",
    "assets/img/fisher/03.jpg",
    "assets/img/fisher/04.jpg",

  ];

 
  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">Fisheries Unit</h2>
            <ul class="breadcrumb-menu">
              <li>
                <a to="index.html">Home</a>
              </li>
              <li class="active">Fisheries Unit</li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "530px" }}>
                          <img
                            src={"assets/img/fisher/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                      </div>
                    </div>

                   
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">Fisheries Unit</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>With low investments
                        and proper management, it can be made in to a profitable
                        venture for small and marginal farmers.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The focus is on creating a
                        demonstration effect through individual where success of
                        one fish farmer could motivate others to take up fish
                        farming.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>We offer Complete
                        consultation from Crop selection, Selection of
                        Techniques, to complete Infrastructure and Aqauaculture
                        Set-up.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Under this unit of Khistiz
                        Agro Tech (KAPL) we help Farmers in their Doubling
                        Income.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                      <h4 class="mb-3"></h4>
                      <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i> Khistiz Agro Tech (KAPL) has also trained to Farmers in
                        FISH FARMING Technology and develops a new and modern
                        technology of Fish Farming model of Aqua-culture system
                        for the Farmers.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i> Fish Farming is a form of Aquaculture in which fish are
                        raised in enclosures to be sold as food. It is the
                        Fastest Growing area of Animal Food Production. Today,
                        about half the Fish consumed Globally are in these
                        Artificial Environments. Commonly farmed species
                        including Salmon, Tuna, Cod, Trout and Halibut.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>The main objective of the KAPL is to provide the much
                        needed field mechanism to popularize composite fish
                        culture among the farmers and co-ordinate the activities
                        of various institutional agencies in fishery development
                        programs.
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i> Objectives of KAPL are: 1. To effectively introduce and
                        popularize improved technique of fish and prawn culture
                        and integrated farming. 2. To provide training and to
                        popularize a vocation by way of fish culture, thereby
                        providing self- employment.
                      </li>
                    </ul>
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        
 <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-4" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default FisheriesUnit;
