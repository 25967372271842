import React, { useState} from "react";
import { Link } from "react-router-dom";
import "./Function.css";
import Lightbox from "react-image-lightbox"; // Import Lightbox component
import "react-image-lightbox/style.css"; // Import Lightbox CSS

function GoateryUnit() {
  const [isOpen, setIsOpen] = useState(false);
const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "assets/img/goat/02.jpg",
    "assets/img/goat/03.jpg",
    "assets/img/goat/04.jpg",
    "assets/img/goat/05.jpg",
  ];

  return (
    <>
      <main class="main">
        {/* <!-- breadcrumb --> */}
        <div
          class="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/05.jpg)" }}
        >
          <div class="container">
            <h2 class="breadcrumb-title">Goatery Unit </h2>
            <ul class="breadcrumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active"> Goatery Unit </li>
            </ul>
          </div>
        </div>

        {/* <!-- room-single --> */}
        <div class="room-single py-120">
          <div class="container">
            <div class="listing-wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <div class="listing-content">
                    <div class="listing-content">
                      <div class="listing-slider">
                        <div class="carousel-images" style={{height: "480px" }}>
                          <img
                            src={"assets/img/goat/01.jpg"}
                            alt={`Room `}
                            class="carousel-image"
                          />
                        </div>
                       
                      </div>
                    </div>

                  
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="booking-sidebar listing-side-content">
                    <h4 class="booking-title">GOAT FARMING</h4>
                    <ul class="listing-side-list">
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Khistiz Agro Tech
                        (KAPL) has also trained to Farmers in GOAT FARMING and
                        develops production model of IFS (Integrated Farming
                        System).
                      </li>
                      <li style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <i class="far fa-check"></i>Goat is known as ‘Poor
                        man’s cow’ in India and is a very important component in
                        dry land farming system. Marginal or undulating lands
                        unsuitable for other types of animals like cow or
                        buffalo but goat is the best alternative. With very low
                        investments goat rearing can be made in to a profitable
                        venture for small and marginal farmers.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="listing-item">
                      <h4 class="mb-3">General Information </h4>
                      <p style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        Goats are reared for milk and meat. Goat is a multi-
                        functional animal and plays a significant role in the
                        economy and nutrition of landless, small and marginal
                        farmers in the country. Goat rearing is an enterprise,
                        which has been practiced by a large section of
                        population in rural areas. Goats can efficiently survive
                        on available shrubs and trees in adverse harsh
                        environment in low fertility lands where no other crop
                        can be grown. Around the world, more people drink goat
                        milk than cow milk. Also, more people eat goat meat. The
                        WHO says that more than 70% of the world’s population
                        has some allergy to cow milk. The allergic symptoms
                        could be stomach-aches, gas, skin rashes and ear
                        infections. An allergy to goat milk is very rare.
                        According to many historians, goats were the first
                        animals to be domesticated. For thousands of years, they
                        have been utilized for their milk, meat, hair, and skins
                        all over the world.{" "}
                      </p>
                      <p class="mt-3" style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        Under this unit of KAPL kept BLACK BENGAL. For the
                        Promotion of Sustainable livelihood diversification and
                        raise the income level by Khistiz Agro Tech (KAPL) also
                        promoted Goat Rearing by distributing Black Bengal to
                        member farmers.{" "}
                      </p>
                    </div>
            </div>
          </div>
        </div>
        {/* <!-- room-single end --> */}
        <div className="gallery-area ">
          <div className="container">
            <div className="row popup-gallery " style={{ marginTop: "-60px" }}>
              {images.map((image, index) => (
                <div className="col-md-6" key={index}>
                  <div className="gallery-item">
                    <div className="gallery-img">
                      <img
                        src={image}
                        alt={`Gallery ${index + 1}`}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%", // Makes image stretch to the container's width
                          height: "280px", // Fixed height
                          objectFit: "cover", // Ensures the image covers the space properly
                        }}
                      />
                    </div>
                    <div className="gallery-content">
                      <Link
                        className="popup-img gallery-link"
                        to={image}
                        onClick={(e) => {
                          e.preventDefault();
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      >
                        <i className="far fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* gallery-area end */}

        {/* Lightbox for Viewing Images */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Set the main image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </main>
    </>
  );
}

export default GoateryUnit;
